import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from 'gatsby'
import websitePrivacyNotice from '../assets/pdf/Noroutine_Website_Privacy_Notice.pdf'
import websiteCookiesPolicy from '../assets/pdf/Noroutine_Website_Cookies_Policy.pdf'
import candidatePrivacyNotice from '../assets/pdf/Noroutine_Candidate_Privacy_Notice.pdf'
import dataProcessingConsentForm from '../assets/pdf/Noroutine_Data_Processing_Consent_Form.pdf'
import dataProcessingWithdrawalForm from '../assets/pdf/Noroutine_Data_Processing_Withdrawal_Form.pdf'

const Privacy = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Privacy" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Privacy" 
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="privacy-policy-content">

                                <p>
This website is operated by <strong>Noroutine GmbH</strong>.
</p>
<p>
We are committed to protecting and preserving the privacy of our guests when visiting our site or communicating electronically with us.
</p>
<p>
Below you find policies that set out how we process any personal data we collect from you or that you provide to us through our website or other channels. 
</p>
<p>
We confirm that we will keep your information secure and that we will comply fully with GDPR. 
Please read the following carefully to understand what happens to personal data that you choose to provide to us, 
or that we collect from you when you visit this site. By visiting <Link to="https://noroutine.com/">https://noroutine.com/</Link>, <Link to="https://apply.nrtn.io/">https://apply.nrtn.io/</Link> you are accepting and consenting to the practices described in below policies.
</p>
                        <ul>
                                <li><i><a href={websitePrivacyNotice} target="_blank" rel="noreferrer">Website Privacy Notice</a></i></li>
                                <li><i><a href={websiteCookiesPolicy} target="_blank" rel="noreferrer">Website Cookies Policy</a></i></li>
                                <li><i><a href={candidatePrivacyNotice} target="_blank" rel="noreferrer">Candidate Privacy Notice</a></i></li>
                        </ul>
<p>You can use below forms, in case you wish to provide explicit consent or request a withdrawal of your consent</p>
                        <ul>
                                <li><i><a href={dataProcessingConsentForm} target="_blank" rel="noreferrer">Data Processing Consent Form</a></i></li>
                                <li><i><a href={dataProcessingWithdrawalForm} target="_blank" rel="noreferrer">Data Processing Withdrawal Form</a></i></li>
                        </ul>
<p>We may have legitimate reasons to continue keep and process your data, even when you wish to execute your privacy rights</p>
<p>Company only processes your personal information in compliance with above privacy notices and policies, in ways governed by our internal security and privacy policies and in accordance with the relevant data protection laws.</p>
<p>If you wish to raise a complaint regarding the processing of your personal data or are unsatisfied with how we have handled your information please contact:

<blockquote>
<p>Noroutine GmbH</p>
<p>Data Protection Officer</p>
<p>Berg-am-Laim Str. 64</p>
<p>81673 München</p>
</blockquote>
or electronically at

<blockquote>
<a href="mailto:dpo@noroutine.com">dpo@noroutine.com</a>
</blockquote>
You also have the right to complain to the supervisory authority in the Germany for data protection matters – The Federal Commissioner for Data Protection and Freedom of Information (BfDI). If you think your data protection rights have been breached in any way by us, you are able to make a complaint to the BfDl.

                        </p>
                            </div>
                        </div>
{/*
                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/about-us">
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                Purchase Guide
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                Terms of Service
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="widget widget_recent_courses">
                                    <h3 className="widget-title">Recent Courses</h3>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg1" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$49.00</span>
                                            <h4 className="title usmall"><Link to="#">The Data Science Course 2020: Complete Data Science Bootcamp</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg2" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$59.00</span>
                                            <h4 className="title usmall"><Link to="#">Java Programming MasterclassName for Software Developers</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="#" className="thumb">
                                            <span className="fullimage cover bg3" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <span>$69.00</span>
                                            <h4 className="title usmall"><Link to="#">Deep Learning A-Z™: Hands-On Artificial Neural Networks</Link></h4>
                                        </div>
                                        <div className="clear"></div>
                                    </article>
                                </div>

                                <div className="widget widget_tag_cloud">
                                    <h3 className="widget-title">Popular Tags</h3>

                                    <div className="tagcloud">
                                        <Link to="#">Business <span className="tag-link-count"> (3)</span></Link>
                                        <Link to="#">Design <span className="tag-link-count"> (3)</span></Link>
                                        <Link to="#">Braike <span className="tag-link-count"> (2)</span></Link>
                                        <Link to="#">Fashion <span className="tag-link-count"> (2)</span></Link>
                                        <Link to="#">Travel <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Smart <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Marketing <span className="tag-link-count"> (1)</span></Link>
                                        <Link to="#">Tips <span className="tag-link-count"> (2)</span></Link>
                                    </div>
                                </div>
                            </aside>
                        </div>
            */}
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default Privacy;